import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-set-secret',
  templateUrl: './set-secret.component.html',
  styleUrls: ['./set-secret.component.css']
})
export class SetSecretComponent implements OnInit {

  @Input("title") title:string = "";
  @Input("question1") question1:string = "";
  @Input("question2") question2:string = "";
  @Input("answer1") answer1Placeholder:string = "";
  @Input("answer2") answer2Placeholder:string = "";
  @Input("reset") reset:boolean = false;
  formGroup: FormGroup;
  
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      secretQuestion1: ['', Validators.required],
      secretAnswer1: ['', Validators.required],
      secretQuestion2: ['', Validators.required],
      secretAnswer2: ['', Validators.required]
    });
  }

  getValue() {
    this.validateAllFormFields();
    return this.formGroup.value;
  }

  isValidForActivation() {
    return this.formGroup.valid;
  }
  
  validateAllFormFields() {
    Object.keys(this.formGroup.controls).forEach(field => {
      const control = this.formGroup.get(field);
      control.markAsTouched();
      control.updateValueAndValidity();
    });
  }

}
