import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AcctUtilService {
  ssoEndpoint: string = environment.ssoEndpoint;
  acctCreateAcctSvc = this.ssoEndpoint + "/manage/create";
  acctGetQuestionsSvc = this.ssoEndpoint + "/manage/getquestions";
  acctValidateSecretSvc = this.ssoEndpoint + "/manage/validate";
  acctResetPassSvc = this.ssoEndpoint + "/manage/setpass";

  constructor(private http: HttpClient) { }

  activateAccount(data): Observable<any> {
    return this.http.post(this.acctCreateAcctSvc, data);
  }

  getQuestions(username): Observable<any> {
    return this.http.get(this.acctGetQuestionsSvc+"?username="+username);
  }

  validateSecret(data): Observable<any> {
    return this.http.post(this.acctValidateSecretSvc, data);
  }

  resetPassword(data): Observable<any> {
    return this.http.post(this.acctResetPassSvc, data);
  }
}
